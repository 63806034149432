import { ConfigFromEnvVariables } from '../appConfig';

declare const APP_ENV: any;

const prodConfig: ConfigFromEnvVariables = {
	ENV_FULL_NAME: APP_ENV.ENV_FULL_NAME,
	IMAGE_TAG: APP_ENV.IMAGE_TAG,
	FALLBACK_API_BASE: APP_ENV.FALLBACK_API_BASE,
	FALLBACK_SHARE_BASE: APP_ENV.FALLBACK_SHARE_BASE,
	FALLBACK_PUBLISH_BASE: APP_ENV.FALLBACK_PUBLISH_BASE,
	FACEBOOK_APP_ID: APP_ENV.FACEBOOK_APP_ID,
	DRM_LICENCE_ENDPOINT_KEY: APP_ENV.DRM_LICENCE_ENDPOINT_KEY,
	ENDPOINTS_URL: APP_ENV.ENDPOINTS_URL,
	FALLBACK_APP_URL: APP_ENV.FALLBACK_APP_URL,
	STRIPE_PUBLISHABLE_KEY: APP_ENV.STRIPE_PUBLISHABLE_KEY,
	GOOGLE_ANALYTICS_MEASUREMENT_ID: APP_ENV.GOOGLE_ANALYTICS_MEASUREMENT_ID,
	GOOGLE_RECAPTCHA_SITE_KEY: APP_ENV.GOOGLE_RECAPTCHA_SITE_KEY,
	CHROME_CAST_RECEIVER_ID: APP_ENV.CHROME_CAST_RECEIVER_ID,
	SENTRY_DSN: APP_ENV.SENTRY_DSN,
	AB_TEST_CAROUSEL_ID: APP_ENV.AB_TEST_CAROUSEL_ID,
	FLAGSMITH_ENVIRONMENT_ID: APP_ENV.FLAGSMITH_ENVIRONMENT_ID,
	FLAGSMITH_POLLING_INTERVAL_MS: +APP_ENV.FLAGSMITH_POLLING_INTERVAL_MS,
	STREAM_CHAT_KEY: APP_ENV.STREAM_CHAT_KEY,
	VAST_URL: APP_ENV.VAST_URL,
	USERPILOT_TOKEN: APP_ENV.USERPILOT_TOKEN,
	ENABLE_PSEUDO: false,
	MIXPANEL_TOKEN: APP_ENV.MIXPANEL_TOKEN
};

export default prodConfig;
