import { AUTH_TOKENS_KEY, LOGGED_BROADCAST_CHANNEL, LOGGED_OUT_EVENT } from '../../constants/Common';
import { setIsLoggedFlagInCookies } from './authManager';

interface AuthTokens {
	accessToken: string;
	refreshToken: string;
}

export const loggedChannel =
	typeof window !== 'undefined' && 'BroadcastChannel' in window
		? new BroadcastChannel(LOGGED_BROADCAST_CHANNEL)
		: undefined;

export const getAuthTokens = (): AuthTokens => {
	const tokens = localStorage.getItem(AUTH_TOKENS_KEY);
	return tokens ? JSON.parse(tokens) : { accessToken: '', refreshToken: '' };
};

export const getAuthAccessToken = (): string => {
	const tokens = getAuthTokens();
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	return tokens ? tokens.accessToken : '';
};

export const getAuthRefreshToken = (): string => {
	const tokens = getAuthTokens();
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	return tokens ? tokens.refreshToken : '';
};

export const setAuthTokens = (tokens: object) => {
	localStorage.setItem(AUTH_TOKENS_KEY, JSON.stringify(tokens));
	setIsLoggedFlagInCookies();
};

export const clearAuthTokens = () => {
	localStorage.removeItem(AUTH_TOKENS_KEY);
	loggedChannel?.postMessage(LOGGED_OUT_EVENT);
};

export const onTokensUpdated = (callback: (tokens: AuthTokens | null) => void) => {
	const listener = (event: StorageEvent) => {
		if (event.key === AUTH_TOKENS_KEY) {
			callback(getAuthTokens());
		}
	};

	window.addEventListener('storage', listener);
	return () => {
		window.removeEventListener('storage', listener);
	};
};
