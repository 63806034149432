import axios, { AxiosResponse } from 'axios';
import { ApiError, ApiResponse, hasError, getBaseApiUrl } from '../api/api';
import { clearAuthTokens, getAuthRefreshToken, setAuthTokens } from '../../domain/auth/authTokenManager';
import { RecaptchaData } from '../../hooks/useRecaptcha';
import { identifyUserForFlags, stopIdentifyingUserForFlags } from '../featureFlags/featureFlags';

export enum AuthApiErrorType {
	EmailAlreadyInUseError = 'EmailAlreadyInUseError',
	RequestFormatError = 'RequestFormatError',
	PasswordTooShortError = 'PasswordTooShortError',
	VerificationCodeInvalidError = 'VerificationCodeInvalidError',
	AccountNotValidatedError = 'AccountNotValidatedError',
	InvalidCredentialsError = 'InvalidCredentialsError',
	ExternalAuthProviderFailure = 'ExternalAuthProviderFailure',
	ExternalAuthProviderCancelled = 'ExternalAuthProviderCancelled',
	EmailRequiredError = 'EmailRequiredError',
	UserDeletedError = 'UserDeletedError'
}

export enum ResendValidationCodeType {
	Email,
	Phone
}

export interface AuthResponse<ResponseDataType = Record<string, unknown>> extends ApiResponse {
	shouldBypassVerification: boolean;
	data: ResponseDataType;
}

export interface AuthLoginResponse extends ApiResponse {
	accessToken: string;
	refreshToken: string;
}

export interface LoginUserRequest {
	email?: string;
	password?: string | null;
	phone?: string | null;
	verificationCode?: string;
	resetPasswordCode?: string;
	socialSignupCode?: string;
}

export interface RegistrationDetails {
	phone?: string;
	email: string;
	password: string;
	referrer?: string | null;
	marketingConsent: number;
	utm_source?: string | null;
	utm_medium?: string | null;
	utm_campaign?: string | null;
	utm_term?: string | null;
	utm_content?: string | null;
	emailValidationToken: string;
	recaptchaData?: RecaptchaData;
}

export interface PostRegistrationState {
	phone: string | null;
	email: string | null;
	shouldBypassVerification?: boolean | null;
}

export enum EmailValidity {
	VALID = 'VALID',
	SUSPECTED = 'SUSPECTED',
	INVALID = 'INVALID'
}

interface RegisterUserData {
	tokens: AuthLoginResponse;
	shouldBypassVerification?: boolean;
}

export interface EmailValidationData {
	valid: EmailValidity;
	token?: string;
}

export const getAuthApiUrl = (suffix: string): string => `${getBaseApiUrl()}/auth/${suffix}`;

export const loginUser = async (userObj: LoginUserRequest) => {
	const res = await axios.post<AxiosResponse<AuthLoginResponse>>(getAuthApiUrl('login'), {
		username: userObj.email,
		password: userObj.password,
		socialSignup: userObj.socialSignupCode
	});
	setAuthTokens(res.data.data);
	await identifyUserForFlags(res.data.data.accessToken);
};

export const validateEmail = async (email: string) => {
	const response = await axios.get<AuthResponse<EmailValidationData>>(getAuthApiUrl('validation/email'), {
		params: { email }
	});
	return response.data.data;
};

export const registerUser = async (details: RegistrationDetails) => {
	const res = await axios.post<AuthResponse<RegisterUserData>>(getAuthApiUrl('signup'), details);
	if (res.data.data.shouldBypassVerification) {
		setAuthTokens(res.data.data.tokens);
		await identifyUserForFlags(res.data.data.tokens.accessToken);
	}
	return res.data.data;
};

export const verifyUser = async (details: LoginUserRequest) => {
	const res = await axios.post<AxiosResponse<AuthLoginResponse>>(getAuthApiUrl('verify'), details);
	setAuthTokens(res.data.data);
	await identifyUserForFlags(res.data.data.accessToken);
};

export const setNewPassword = async (userObj: LoginUserRequest) => {
	const res = await axios.post<AuthResponse>(getAuthApiUrl('login'), {
		password: userObj.password,
		verificationCode: userObj.resetPasswordCode
	});
	setAuthTokens(res.data.data);
};

export const postRefreshToken = async () => {
	const res = await axios.post(getAuthApiUrl('refresh-token'), { refreshToken: getAuthRefreshToken() });
	setAuthTokens(res.data);
};

export const logout = async () => {
	await axios.delete(getAuthApiUrl('logout'), { data: { refreshToken: getAuthRefreshToken() } }).finally(async () => {
		clearAuthTokens();
		await stopIdentifyingUserForFlags();
	});
};

export const resetPassword = async (email: string) => {
	const res = await axios.post(getAuthApiUrl('resetPassword'), { email });
	return res.data;
};

export const resendValidationCode = async (
	contactValue: string,
	type: ResendValidationCodeType,
	recaptchaData?: RecaptchaData
) => {
	const data =
		type === ResendValidationCodeType.Email
			? { email: contactValue, ...(!!recaptchaData && { recaptchaData }) }
			: { phone: contactValue, ...(!!recaptchaData && { recaptchaData }) };
	const res = await axios.post(getAuthApiUrl('verify-code'), data);
	return res.data;
};

export const updateSignupPhone = async (
	phone: string,
	email: string,
	password: string,
	recaptchaData?: RecaptchaData
) => {
	return await axios.post(getAuthApiUrl('signup/updatePhone'), {
		email,
		phone,
		password,
		...(!!recaptchaData && { recaptchaData })
	});
};

export const isUnverifiedAccountError = (errorMessages?: ApiError[]): boolean => {
	return hasError(errorMessages, AuthApiErrorType.AccountNotValidatedError);
};

export const isInvalidCredentialsError = (errorMessages?: ApiError[]): boolean => {
	return hasError(errorMessages, AuthApiErrorType.InvalidCredentialsError);
};

export const isVerificationCodeInvalidError = (errorMessages?: ApiError[]): boolean => {
	return (
		hasError(errorMessages, AuthApiErrorType.VerificationCodeInvalidError) ||
		hasError(errorMessages, AuthApiErrorType.RequestFormatError)
	);
};

export enum SocialSignupType {
	google = 'google',
	facebook = 'facebook'
}

export const getSocialSignupUrl = (type: SocialSignupType, source: 'fanApp' | 'popUp', referrer?: string | null) => {
	const query = new URLSearchParams();
	referrer && query.set('referrer', referrer);
	query.set('source', source);

	return `${getAuthApiUrl(type)}${query.toString() ? `?${query.toString()}` : ''}`;
};

const socialSignupProviders: Record<SocialSignupType, string> = {
	[SocialSignupType.facebook]: 'Facebook',
	[SocialSignupType.google]: 'Google'
};

export const getSocialSignupProvider = (type: SocialSignupType) => socialSignupProviders[type];
